import { React } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { Link } from "gatsby";

const LearnMore = ({ link}) => {
    return (
        <p className="mt-8">
        <Link to={link} className="text-blue-700 hover:text-blue-800"><FontAwesomeIcon icon={faLink} /> Find out more</Link>
        </p>
    )
}

export default LearnMore;