// UseCasesSection.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserClock, faDumbbell, faGraduationCap, faWarehouse, 
    faBusinessTime, faUniversalAccess, faAmbulance, faStore } from '@fortawesome/free-solid-svg-icons';

const useCases = [
    {label:'Time tracking in construction sites',icon: faUserClock},
    {label:'Gym turnstyle customers recognition',icon: faDumbbell},
    {label:'School staff time tracking',icon: faGraduationCap},
    {label:'Warehouse shifts and time tracking',icon: faWarehouse},
    {label:'Time tracking and attendance management',icon: faBusinessTime},
    {label:'Access control and security',icon: faUniversalAccess},
    {label:'Emergency evacuation management',icon: faAmbulance},
    {label:'Employee self-service portal',icon: faStore},
    // Add more use cases as needed
];

const UseCasesSection = () => {
    return (
        <div className="mt-20 shadow-md p-8 bg-blue-100 rounded-3xl">
            <h2 className="mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl dark:text-white">
                Our customers <mark className="px-2 text-white bg-blue-600 rounded dark:bg-blue-500">business cases</mark>:
            </h2>
            <div className="ml-8 text-lg font-normal text-gray-800 lg:text-xl dark:text-gray-400">
                <ul className="flex flex-wrap">
                    {useCases.map((item, index) => {
                        return (
                            <li key={index} className="w-1/2 mb-4">
                                <FontAwesomeIcon icon={item.icon} className="mr-6" style={{ color: '#999', marginRight: '2rem' }} />
                                {item.label}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default UseCasesSection;
